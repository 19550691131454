/*
	zhHans 中文简体
	en 英文
	ja 日语
	type 1-4 => N-UR
	mining_power 挖矿值
	god_strength 分红值
	bonus_proportion 兑换比例
	combined_list 可合成卡牌类型
	combined 合成需要卡牌类型、数量
*/
export default {
	'1-0': {
		zhHans: '花弁',
		en: 'Petals',
		ja: '花弁',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-0'],
		combined: [
			[
				['4-2', '1-0', '1-1', '3-3', '5-2'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'1-1': {
		zhHans: '向日葵',
		en: 'Sunflower',
		ja: '向日葵',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-0'],
		combined: [
			[
				['4-2', '1-0', '1-1', '3-3', '5-2'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'1-2': {
		zhHans: '白山辰',
		en: 'Dragon Car',
		ja: '白山辰',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-1'],
		combined: [
			[
				['1-2', '1-3', '2-0', '3-4', '2-36', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-3': {
		zhHans: '白山辰鳥',
		en: 'Dragon Bird',
		ja: '白山辰鳥',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-1'],
		combined: [
			[
				['1-2', '1-3', '2-0', '3-4', '2-36', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-4': {
		zhHans: '白山巳鳥',
		en: 'Serpent Bird',
		ja: '白山巳鳥',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-2'],
		combined: [
			[
				['3-5', '2-1', '1-4', '2-17', '2-22', '2-37', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-5': {
		zhHans: '白山午鳥',
		en: 'Horse Bird',
		ja: '白山午鳥',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-3', '9-4'],
		combined: [
			[
				['3-6', '2-2', '1-5', '2-16', '3-28', '2-38', '5-2'],
				[1, 1, 1, 1, 1, 1, 1],
			],
			[
				['1-5', '3-7', '2-43', '5-0'],
				[1, 1, 1, 1],
			],
		],
	},
	'1-6': {
		zhHans: '白山申',
		en: 'Smiling Monkey',
		ja: '白山申',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-5'],
		combined: [
			[
				['3-8', '2-3', '1-6', '1-7', '2-39', '5-1'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-7': {
		zhHans: '白山申鳥',
		en: 'Monkey Bird',
		ja: '白山申鳥',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-5'],
		combined: [
			[
				['3-8', '2-3', '1-6', '1-7', '2-39', '5-1'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-8': {
		zhHans: '白山酉',
		en: 'Phoenix',
		ja: '白山酉',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-6'],
		combined: [
			[
				['3-9', '1-8', '1-9', '2-4', '2-40', '5-0'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-9': {
		zhHans: '白山酉鳥',
		en: 'Flying Bird',
		ja: '白山酉鳥',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-6'],
		combined: [
			[
				['3-9', '1-8', '1-9', '2-4', '2-40', '5-0'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-10': {
		zhHans: '白山戌鳥',
		en: 'Dog Bird',
		ja: '白山戌鳥',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-7'],
		combined: [
			[
				['3-10', '3-11', '3-12', '1-10', '2-5', '2-41', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-11': {
		zhHans: '白山丑',
		en: 'Ox Bird',
		ja: '白山丑',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-10'],
		combined: [
			[
				['3-15', '2-7', '1-11', '3-29', '3-33', '3-45', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-12': {
		zhHans: '白山寅',
		en: 'Tiger Bird',
		ja: '白山寅',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-11'],
		combined: [
			[
				['3-16', '2-8', '1-12', '3-34', '5-0'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'1-13': {
		zhHans: '巫女乃花弁',
		en: 'Miko petals',
		ja: '巫女乃花弁',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-16'],
		combined: [
			[
				['1-13', '2-16', '3-27', '3-28', '4-6', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-14': {
		zhHans: '宇賀',
		en: 'Uga',
		ja: '宇賀',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-15': {
		zhHans: '宇賀蛙',
		en: 'Uga Frog',
		ja: '宇賀蛙',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-16': {
		zhHans: '宇賀炎',
		en: 'Uga Fire',
		ja: '宇賀炎',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-17': {
		zhHans: '宇賀神',
		en: 'God Uga',
		ja: '宇賀神',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-18': {
		zhHans: '宇賀畑',
		en: 'Uga Farm',
		ja: '宇賀畑',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-19': {
		zhHans: '宇賀玉',
		en: 'Uga Ball',
		ja: '宇賀玉',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-20': {
		zhHans: '宇賀地蔵',
		en: 'Uga Jizo',
		ja: '宇賀地蔵',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-21': {
		zhHans: '宇賀餅',
		en: 'Uga rice cake',
		ja: '宇賀餅',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-22': {
		zhHans: '釈迦',
		en: 'Syaka',
		ja: '釈迦',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-23': {
		zhHans: '亀',
		en: 'Turtle',
		ja: '亀',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-24': {
		zhHans: '三銃士',
		en: 'The Three Musketeers',
		ja: '三銃士',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-25': {
		zhHans: '印度小僧',
		en: 'Indian Boy',
		ja: '印度小僧',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-26': {
		zhHans: '小念',
		en: 'Small Nun',
		ja: '小念',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-27': {
		zhHans: '小念賽銭箱',
		en: 'SMall Nun Box',
		ja: '小念賽銭箱',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-28': {
		zhHans: '跳小念',
		en: 'Jamping Small Nun',
		ja: '跳小念',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-29': {
		zhHans: '人形',
		en: 'Doll',
		ja: '人形',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-12'],
		combined: [
			[
				['2-9', '2-10', '3-17', '2-34', '1-29', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'1-30': {
		zhHans: '弍七面様',
		en: 'Seven Faces Ⅱ',
		ja: '弍七面様',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-31': {
		zhHans: '召使',
		en: 'Servant',
		ja: '召使',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'1-32': {
		zhHans: '梵天',
		en: 'Brahma',
		ja: '梵天',
		type: 1,
		mining_power: 1,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-0': {
		zhHans: '白山辰娘',
		en: 'Dragon Girl',
		ja: '白山辰娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-1'],
		combined: [
			[
				['1-2', '1-3', '2-0', '3-4', '2-36', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-1': {
		zhHans: '白山巳娘',
		en: 'Serpent Girl',
		ja: '白山巳娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-2'],
		combined: [
			[
				['3-5', '2-1', '1-4', '2-17', '2-22', '2-37', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-2': {
		zhHans: '白山午娘',
		en: 'Horse Girl',
		ja: '白山午娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-3'],
		combined: [
			[
				['3-6', '2-2', '1-5', '2-16', '3-28', '2-38', '5-2'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-3': {
		zhHans: '白山申娘',
		en: 'Monkey Girl',
		ja: '白山申娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-5'],
		combined: [
			[
				['3-8', '2-3', '1-6', '1-7', '2-39', '5-1'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-4': {
		zhHans: '白山酉娘',
		en: 'Bird Girl',
		ja: '白山酉娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-6'],
		combined: [
			[
				['3-9', '1-8', '1-9', '2-4', '2-40', '5-0'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-5': {
		zhHans: '白山戌娘',
		en: 'Dog Girl',
		ja: '白山戌娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-7'],
		combined: [
			[
				['3-10', '3-11', '3-12', '1-10', '2-5', '2-41', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-6': {
		zhHans: '白山猪娘',
		en: 'Boar Girl',
		ja: '白山猪娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-8'],
		combined: [
			[
				['3-13', '2-6', '2-42', '3-39', '3-40', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-7': {
		zhHans: '白山丑娘',
		en: 'Ox Girl',
		ja: '白山丑娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-10'],
		combined: [
			[
				['3-15', '2-7', '1-11', '3-29', '3-33', '3-45', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-8': {
		zhHans: '白山寅娘',
		en: 'Tiger Girl',
		ja: '白山寅娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-11'],
		combined: [
			[
				['3-16', '2-8', '1-12', '3-34', '5-0'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'2-9': {
		zhHans: '稲荷右狐',
		en: 'Right Fox',
		ja: '稲荷右狐',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-12'],
		combined: [
			[
				['2-9', '2-10', '3-17', '2-34', '1-29', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-10': {
		zhHans: '稲荷左狐',
		en: 'Left Fox',
		ja: '稲荷左狐',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-12'],
		combined: [
			[
				['2-9', '2-10', '3-17', '2-34', '1-29', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-11': {
		zhHans: '役小角',
		en: 'Ozunu',
		ja: '役小角',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-14'],
		combined: [
			[
				['3-20', '2-11', '2-12', '3-24', '3-25', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-12': {
		zhHans: '菊理比咩命',
		en: 'Kukurihime-mikoto',
		ja: '菊理比咩命',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-14'],
		combined: [
			[
				['3-20', '2-11', '2-12', '3-24', '3-25', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-13': {
		zhHans: '白山神社裏物語',
		en: 'Hakusan-Another-Story',
		ja: '白山神社裏物語',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-15'],
		combined: [
			[
				['3-21', '2-13', '4-4', '4-5', '3-24', '3-25', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-14': {
		zhHans: '御朱印帳女子',
		en: 'Gosyuin note Girl',
		ja: '御朱印帳女子',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'2-15': {
		zhHans: '御朱印帳男子',
		en: 'Gosyuin note Boy',
		ja: '御朱印帳男子',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'2-16': {
		zhHans: '巫女乃鳥',
		en: 'Miko Bird',
		ja: '巫女乃鳥',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-3', '9-9', '9-16'],
		combined: [
			[
				['3-6', '2-2', '1-5', '2-16', '3-28', '2-38', '5-2'],
				[1, 1, 1, 1, 1, 1, 1],
			],
			[
				['3-14', '2-16', '3-32', '3-41', '3-42', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['1-13', '2-16', '3-27', '3-28', '4-6', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-17': {
		zhHans: '七面様',
		en: 'Seven Faces',
		ja: '七面様',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: ['9-2'],
		combined: [
			[
				['3-5', '2-1', '1-4', '2-17', '2-22', '2-37', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-18': {
		zhHans: '贈与吉祥天',
		en: 'Present',
		ja: '贈与吉祥天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-19': {
		zhHans: '節分',
		en: 'Setsubun',
		ja: '節分',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: [],
		combined: [],
	},
	'2-20': {
		zhHans: '野生之羅也',
		en: 'Stray Nari',
		ja: '野生之羅也',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: [],
		combined: [],
	},
	'2-21': {
		zhHans: '幼女化吉祥天',
		en: 'Little Girl Kisshoten',
		ja: '幼女化吉祥天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: [],
		combined: [],
	},
	'2-22': {
		zhHans: '幼女化七面様',
		en: 'Little Girl Seven Faces',
		ja: '幼女化七面様',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: ['9-2'],
		combined: [
			[
				['3-5', '2-1', '1-4', '2-17', '2-22', '2-37', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-23': {
		zhHans: '宇賀天',
		en: 'Uga with Benten',
		ja: '宇賀天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-24': {
		zhHans: '宇賀女神',
		en: 'Goddess of Uga',
		ja: '宇賀女神',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-25': {
		zhHans: '宇賀弁財天',
		en: 'Uga Benzaiten',
		ja: '宇賀弁財天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-26': {
		zhHans: '釈迦馬',
		en: 'Buddha Horse',
		ja: '釈迦馬',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-27': {
		zhHans: '仏陀',
		en: 'Buddha',
		ja: '仏陀',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-28': {
		zhHans: '聖夜弁財天',
		en: 'Christmas　Benzaiten',
		ja: '聖夜弁財天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-29': {
		zhHans: '印度娘',
		en: 'Indian girl',
		ja: '印度娘',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-13'],
		combined: [
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-30': {
		zhHans: '印度姫',
		en: 'Indian Princess',
		ja: '印度姫',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-31': {
		zhHans: '小念蛙',
		en: 'Small Nun Flog',
		ja: '小念蛙',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-32': {
		zhHans: '壊念',
		en: 'Injured Small Nun',
		ja: '壊念',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-33': {
		zhHans: '小念女',
		en: 'Nun',
		ja: '小念女',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-34': {
		zhHans: '也',
		en: 'Nari',
		ja: '也',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-12'],
		combined: [
			[
				['2-9', '2-10', '3-17', '2-34', '1-29', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-35': {
		zhHans: '母',
		en: 'Mother',
		ja: '母',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-17'],
		combined: [
			[
				['4-7', '4-8', '4-9', '4-10', '2-35', '5-4'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-36': {
		zhHans: '壱小弁天',
		en: 'Small Benten  Ⅰ',
		ja: '壱小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-1'],
		combined: [
			[
				['1-2', '1-3', '2-0', '3-4', '2-36', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-37': {
		zhHans: '弍小弁天',
		en: 'Small Benten Ⅱ',
		ja: '弍小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-2'],
		combined: [
			[
				['3-5', '2-1', '1-4', '2-17', '2-22', '2-37', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-38': {
		zhHans: '参小弁天',
		en: 'Small Benten Ⅲ',
		ja: '参小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-3'],
		combined: [
			[
				['3-6', '2-2', '1-5', '2-16', '3-28', '2-38', '5-2'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-39': {
		zhHans: '肆小弁天',
		en: 'Small Benten Ⅳ',
		ja: '肆小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-5'],
		combined: [
			[
				['3-8', '2-3', '1-6', '1-7', '2-39', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-40': {
		zhHans: '伍小弁天',
		en: 'Small Benten Ⅴ',
		ja: '伍小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-6'],
		combined: [
			[
				['3-9', '1-8', '1-9', '2-4', '2-40', '5-0'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-41': {
		zhHans: '陸小弁天',
		en: 'Small Benten Ⅵ',
		ja: '陸小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-7'],
		combined: [
			[
				['3-10', '3-11', '3-12', '1-10', '2-5', '2-41', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-42': {
		zhHans: '漆小弁天',
		en: 'Small Benten Ⅶ',
		ja: '漆小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-8'],
		combined: [
			[
				['3-13', '2-6', '2-42', '3-39', '3-40', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'2-43': {
		zhHans: '捌小弁天',
		en: 'Small Benten Ⅷ',
		ja: '捌小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-4'],
		combined: [
			[
				['1-5', '3-7', '2-43', '5-0'],
				[1, 1, 1, 1],
			],
		],
	},
	'2-44': {
		zhHans: '玖小弁天',
		en: 'Small Benten Ⅸ',
		ja: '小玖小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-45': {
		zhHans: '拾小弁天',
		en: 'Small Benten Ⅹ',
		ja: '拾小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-46': {
		zhHans: '拾壱小弁天',
		en: 'Small Benten Ⅺ',
		ja: '拾壱小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-47': {
		zhHans: '拾弍小弁天',
		en: 'Small Benten Ⅻ',
		ja: '拾弍小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-48': {
		zhHans: '拾参小弁天',
		en: 'Small Benten XIII',
		ja: '拾参小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-49': {
		zhHans: '拾肆小弁天',
		en: 'Small Benten XIV',
		ja: '拾肆小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-50': {
		zhHans: '拾伍小弁天',
		en: 'Small Benten XV',
		ja: '拾伍小弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-51': {
		zhHans: '小也',
		en: 'Small Nari',
		ja: '小也',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-52': {
		zhHans: '弍小也',
		en: 'Small Nari Ⅱ',
		ja: '弍小也',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-53': {
		zhHans: '参小也',
		en: 'Small Nari Ⅲ',
		ja: '参小也',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-54': {
		zhHans: '小里奈',
		en: 'Small Rina',
		ja: '小里奈',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-55': {
		zhHans: '弍小里奈',
		en: 'Small Rina Ⅱ',
		ja: '弍小里奈',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-56': {
		zhHans: '壱小吉祥天',
		en: 'Small Kisshoten Ⅰ',
		ja: '壱小吉祥天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-57': {
		zhHans: '弍小吉祥天',
		en: 'Small Kisshoten Ⅱ',
		ja: '弍小吉祥天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-58': {
		zhHans: '参小吉祥天',
		en: 'Small Kisshoten Ⅲ',
		ja: '参小吉祥天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-59': {
		zhHans: '里奈',
		en: 'Rina',
		ja: '里奈',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-60': {
		zhHans: '風呂里奈',
		en: 'Rina in the Bath',
		ja: '風呂里奈',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-61': {
		zhHans: '壱七面様',
		en: 'Seven Faces Ⅰ',
		ja: '壱七面様',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-62': {
		zhHans: '水着弁天',
		en: 'Swimming Benten',
		ja: '水着弁天',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'2-63': {
		zhHans: '赤子',
		en: 'Baby',
		ja: '赤子',
		type: 2,
		mining_power: 2.28,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-0': {
		zhHans: '八咫鏡',
		en: 'Mirror of Yata ',
		ja: '八咫鏡',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: [],
		combined: [],
	},
	'3-1': {
		zhHans: '八尺瓊勾玉',
		en: 'Magatama of Yasakani ',
		ja: '八尺瓊勾玉',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: [],
		combined: [],
	},
	'3-2': {
		zhHans: '草薙剣',
		en: 'Sword of Kusanagi',
		ja: '草薙剣',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: [],
		combined: [],
	},
	'3-3': {
		zhHans: '姫',
		en: 'Princess',
		ja: '姫',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-0'],
		combined: [
			[
				['4-2', '1-0', '1-1', '3-3', '5-2'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'3-4': {
		zhHans: '白山辰年',
		en: 'Dragon ',
		ja: '白山辰年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-1'],
		combined: [
			[
				['1-2', '1-3', '2-0', '3-4', '2-36', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-5': {
		zhHans: '白山巳年',
		en: 'Serpent',
		ja: '白山巳年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-2'],
		combined: [
			[
				['3-5', '2-1', '1-4', '2-17', '2-22', '2-37', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-6': {
		zhHans: '白山午年',
		en: 'Horse',
		ja: '白山午年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-3'],
		combined: [
			[
				['3-6', '2-2', '1-5', '2-16', '3-28', '2-38', '5-2'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-7': {
		zhHans: '白山未年',
		en: 'Sheep',
		ja: '白山未年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-4'],
		combined: [
			[
				['1-5', '3-7', '2-43', '5-0'],
				[1, 1, 1, 1],
			],
		],
	},
	'3-8': {
		zhHans: '白山申年',
		en: 'Monkey',
		ja: '白山申年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-5'],
		combined: [
			[
				['3-8', '2-3', '1-6', '1-7', '2-39', '5-1'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-9': {
		zhHans: '白山酉年',
		en: 'Bird',
		ja: '白山酉年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-6'],
		combined: [
			[
				['3-9', '1-8', '1-9', '2-4', '2-40', '5-0'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-10': {
		zhHans: '白山戌年',
		en: 'Dog',
		ja: '白山戌年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-7'],
		combined: [
			[
				['3-10', '3-11', '3-12', '1-10', '2-5', '2-41', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-11': {
		zhHans: '白山戌',
		en: 'Small Dogs',
		ja: '白山戌',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-7'],
		combined: [
			[
				['3-10', '3-11', '3-12', '1-10', '2-5', '2-41', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-12': {
		zhHans: '白山子戌',
		en: 'Puppy',
		ja: '白山子戌',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-7'],
		combined: [
			[
				['3-10', '3-11', '3-12', '1-10', '2-5', '2-41', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-13': {
		zhHans: '白山猪',
		en: 'Boar',
		ja: '白山猪',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-8'],
		combined: [
			[
				['3-13', '2-6', '2-42', '3-39', '3-40', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-14': {
		zhHans: '白山子年',
		en: 'Rat',
		ja: '白山子年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-9'],
		combined: [
			[
				['3-14', '2-16', '3-32', '3-41', '3-42', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-15': {
		zhHans: '白山丑年',
		en: 'Ox',
		ja: '白山丑年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-10'],
		combined: [
			[
				['3-15', '2-7', '1-11', '3-29', '3-33', '3-45', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-16': {
		zhHans: '白山寅年',
		en: 'Tiger',
		ja: '白山寅年',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-11'],
		combined: [
			[
				['3-16', '2-8', '1-12', '3-34', '5-0'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'3-17': {
		zhHans: '稲荷神社娘',
		en: 'Inari Shrine Girl',
		ja: '稲荷神社娘',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-12'],
		combined: [
			[
				['2-9', '2-10', '3-17', '2-34', '1-29', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-18': {
		zhHans: '白山神社鳥',
		en: 'Shrine Bird',
		ja: '白山神社鳥',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-13'],
		combined: [
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-19': {
		zhHans: '白山神社娘',
		en: 'Shrine Girl',
		ja: '白山神社娘',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-13'],
		combined: [
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-20': {
		zhHans: '鬼役小角',
		en: 'Ogre Ozunu',
		ja: '鬼役小角',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-14'],
		combined: [
			[
				['3-20', '2-11', '2-12', '3-24', '3-25', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-21': {
		zhHans: '白山神社物語',
		en: 'Hakusan-Story',
		ja: '白山神社物語',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-15'],
		combined: [
			[
				['3-21', '2-13', '4-4', '4-5', '3-24', '3-25', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-22': {
		zhHans: '神社御朱印帳',
		en: 'Gosyuin note',
		ja: '神社御朱印帳',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'3-23': {
		zhHans: '神社御朱印帳男子',
		en: 'Gosyuin note Boy',
		ja: '神社御朱印帳男子',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'3-24': {
		zhHans: '泰澄',
		en: 'Taicho',
		ja: '泰澄',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-14', '9-15'],
		combined: [
			[
				['3-20', '2-11', '2-12', '3-24', '3-25', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['3-21', '2-13', '4-4', '4-5', '3-24', '3-25', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-25': {
		zhHans: '大役小角',
		en: 'Great Ozunu',
		ja: '大役小角',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-14', '9-15'],
		combined: [
			[
				['3-20', '2-11', '2-12', '3-24', '3-25', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['3-21', '2-13', '4-4', '4-5', '3-24', '3-25', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-26': {
		zhHans: '巫女',
		en: 'Maiden',
		ja: '巫女',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'3-27': {
		zhHans: '巫女乃娘',
		en: 'Miko',
		ja: '巫女乃娘',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-16'],
		combined: [
			[
				['1-13', '2-16', '3-27', '3-28', '4-6', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-28': {
		zhHans: 'リース弁天',
		en: 'Wreath',
		ja: 'リース弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: ['9-3', '9-16'],
		combined: [
			[
				['3-6', '2-2', '1-5', '2-16', '3-28', '2-38', '5-2'],
				[1, 1, 1, 1, 1, 1, 1],
			],
			[
				['1-13', '2-16', '3-27', '3-28', '4-6', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-29': {
		zhHans: '歌姉',
		en: 'Song ',
		ja: '歌姉',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: ['9-10'],
		combined: [
			[
				['3-15', '2-7', '1-11', '3-29', '3-33', '3-45', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-30': {
		zhHans: '神玉',
		en: 'Benten Kisshoten',
		ja: '神玉',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-31': {
		zhHans: '婆念',
		en: 'Old Nun',
		ja: '婆念',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-32': {
		zhHans: '弁天',
		en: 'Benten',
		ja: '弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-9'],
		combined: [
			[
				['3-14', '2-16', '3-32', '3-41', '3-42', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-33': {
		zhHans: '壱弁天',
		en: 'Benten Ⅰ',
		ja: '壱弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-10'],
		combined: [
			[
				['3-15', '2-7', '1-11', '3-29', '3-33', '3-45', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-34': {
		zhHans: '弍弁天',
		en: 'Benten Ⅱ',
		ja: '弍弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-11'],
		combined: [
			[
				['3-16', '2-8', '1-12', '3-34', '5-0'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'3-35': {
		zhHans: '参弁天',
		en: 'Benten Ⅲ',
		ja: '参弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-36': {
		zhHans: '肆弁天',
		en: 'Benten Ⅳ',
		ja: '肆弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-37': {
		zhHans: '伍弁天',
		en: 'Benten Ⅴ',
		ja: '伍弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-38': {
		zhHans: '漆弁天',
		en: 'Benten Ⅶ',
		ja: '漆弁天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-13'],
		combined: [
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-39': {
		zhHans: '里奈也',
		en: 'Rina Nari',
		ja: '里奈也',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-8'],
		combined: [
			[
				['3-13', '2-6', '2-42', '3-39', '3-40', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-40': {
		zhHans: '弍里奈也',
		en: 'Rina Nari Ⅱ',
		ja: '弍里奈也',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-8'],
		combined: [
			[
				['3-13', '2-6', '2-42', '3-39', '3-40', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-41': {
		zhHans: '壱吉祥天',
		en: 'Kisshoten Ⅰ',
		ja: '壱吉祥天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-9'],
		combined: [
			[
				['3-14', '2-16', '3-32', '3-41', '3-42', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-42': {
		zhHans: '弍吉祥天',
		en: 'Kisshoten Ⅱ',
		ja: '弍吉祥天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-9'],
		combined: [
			[
				['3-14', '2-16', '3-32', '3-41', '3-42', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-43': {
		zhHans: '参吉祥天',
		en: 'Kisshoten Ⅲ',
		ja: '参吉祥天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-44': {
		zhHans: '肆吉祥天',
		en: 'Kisshoten Ⅳ',
		ja: '肆吉祥天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'3-45': {
		zhHans: '伍吉祥天',
		en: 'Kisshoten Ⅴ',
		ja: '伍吉祥天',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-10'],
		combined: [
			[
				['3-15', '2-7', '1-11', '3-29', '3-33', '3-45', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'3-46': {
		zhHans: '泡',
		en: 'Bubble Time',
		ja: '泡',
		type: 3,
		mining_power: 4.3,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-13'],
		combined: [
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-0': {
		zhHans: '八岐大蛇',
		en: 'Eight-headed Serpent',
		ja: '八岐大蛇',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: [],
		combined: [],
	},
	'4-1': {
		zhHans: '日本武尊',
		en: 'Yamato Takeru',
		ja: '日本武尊',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: [],
		combined: [],
	},
	'4-2': {
		zhHans: '菊理姫',
		en: 'Kukurihime ',
		ja: '菊理姫',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-0'],
		combined: [
			[
				['4-2', '1-0', '1-1', '3-3', '5-2'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'4-3': {
		zhHans: '白山神社巫女',
		en: 'Shrine Maiden',
		ja: '白山神社巫女',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-13'],
		combined: [
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-4': {
		zhHans: '白山神社御朱印帳',
		en: 'Hakusan Gosyuin note ',
		ja: '白山神社御朱印帳',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-15'],
		combined: [
			[
				['3-21', '2-13', '4-4', '4-5', '3-24', '3-25', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-5': {
		zhHans: '白山神社御朱印帳男子',
		en: 'Hakusan Gosyuin note Boy',
		ja: '白山神社御朱印帳男子',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '白山',
		combined_list: ['9-15'],
		combined: [
			[
				['3-21', '2-13', '4-4', '4-5', '3-24', '3-25', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-6': {
		zhHans: '九尾',
		en: 'Kyubi',
		ja: '九尾',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: 'その他',
		combined_list: ['9-16'],
		combined: [
			[
				['1-13', '2-16', '3-27', '3-28', '4-6', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-7': {
		zhHans: '金宇賀様',
		en: 'Golden Uga',
		ja: '金宇賀様',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-17'],
		combined: [
			[
				['4-7', '4-8', '4-9', '4-10', '2-35', '5-4'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-8': {
		zhHans: '聖夜祭',
		en: 'Christmas',
		ja: '聖夜祭',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-17'],
		combined: [
			[
				['4-7', '4-8', '4-9', '4-10', '2-35', '5-4'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-9': {
		zhHans: '陸弁天',
		en: 'Benten Ⅵ',
		ja: '陸弁天',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-13', '9-17'],
		combined: [
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
			[
				['4-7', '4-8', '4-9', '4-10', '2-35', '5-4'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-10': {
		zhHans: '捌弁天',
		en: 'Benten Ⅷ',
		ja: '捌弁天',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: ['9-17'],
		combined: [
			[
				['4-7', '4-8', '4-9', '4-10', '2-35', '5-4'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'4-11': {
		zhHans: '玖弁天',
		en: 'Benten Ⅸ',
		ja: '玖弁天',
		type: 4,
		mining_power: 19,
		god_strength: 0,
		bonus_proportion: 0,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
	'5-0': {
		zhHans: '素戔嗚尊',
		en: 'Susanoo',
		ja: '素戔嗚尊',
		type: 5,
		mining_power: 189,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: ['9-4', '9-6', '9-11'],
		combined: [
			[
				['1-5', '3-7', '2-43', '5-0'],
				[1, 1, 1, 1],
			],
			[
				['3-9', '1-8', '1-9', '2-4', '2-40', '5-0'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['3-16', '2-8', '1-12', '3-34', '5-0'],
				[1, 1, 1, 1, 1],
			],
		],
	},
	'5-1': {
		zhHans: '大国主命',
		en: 'Okuninushi',
		ja: '大国主命',
		type: 5,
		mining_power: 189,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: ['9-5', '9-10', '9-15'],
		combined: [
			[
				['3-8', '2-3', '1-6', '1-7', '2-39', '5-1'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['3-15', '2-7', '1-11', '3-29', '3-33', '3-45', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
			[
				['3-21', '2-13', '4-4', '4-5', '3-24', '3-25', '5-1'],
				[1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'5-2': {
		zhHans: '伊弉諾神',
		en: 'Izanami ',
		ja: '伊弉諾神',
		type: 5,
		mining_power: 189,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: ['9-0', '9-3', '9-8', '9-12', '9-13'],
		combined: [
			[
				['4-2', '1-0', '1-1', '3-3', '5-2'],
				[1, 1, 1, 1, 1],
			],
			[
				['3-6', '2-2', '1-5', '2-16', '3-28', '2-38', '5-2'],
				[1, 1, 1, 1, 1, 1, 1],
			],
			[
				['3-13', '2-6', '2-42', '3-39', '3-40', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['2-9', '2-10', '3-17', '2-34', '1-29', '5-2'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['4-3', '3-18', '3-19', '2-29', '4-9', '3-38', '3-46', '5-2'],
				[1, 1, 1, 1, 1, 1, 1, 1],
			],
		],
	},
	'5-3': {
		zhHans: '伊邪那美神',
		en: 'Izanagi ',
		ja: '伊邪那美神',
		type: 5,
		mining_power: 189,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: ['9-1', '9-2', '9-7', '9-9', '9-14', '9-16'],
		combined: [
			[
				['1-2', '1-3', '2-0', '3-4', '2-36', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['3-5', '2-1', '1-4', '2-17', '2-22', '2-37', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
			[
				['3-10', '3-11', '3-12', '1-10', '2-5', '2-41', '5-3'],
				[1, 1, 1, 1, 1, 1, 1],
			],
			[
				['3-14', '2-16', '3-32', '3-41', '3-42', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['3-20', '2-11', '2-12', '3-24', '3-25', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
			[
				['1-13', '2-16', '3-27', '3-28', '4-6', '5-3'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'5-4': {
		zhHans: '天照大神',
		en: 'Amaterasu (the Sun Goddess)',
		ja: '天照大神',
		type: 5,
		mining_power: 189,
		god_strength: 0,
		bonus_proportion: 0,
		series: '天照',
		combined_list: ['9-17'],
		combined: [
			[
				['4-7', '4-8', '4-9', '4-10', '2-35', '5-4'],
				[1, 1, 1, 1, 1, 1],
			],
		],
	},
	'9-0': {
		zhHans: '菊理暖命',
		en: 'God Kukurihime ',
		ja: '菊理暖命',
		type: 9,
		mining_power: 0,
		god_strength: 1,
		bonus_proportion: 1,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-1': {
		zhHans: '白山龍年',
		en: 'God Dragon',
		ja: '白山龍年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-2': {
		zhHans: '白山蛇年',
		en: 'God Serpent',
		ja: '白山蛇年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-3': {
		zhHans: '白山馬年',
		en: 'God Horse',
		ja: '白山馬年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-4': {
		zhHans: '白山羊年',
		en: 'God Sheep',
		ja: '白山羊年',
		type: 9,
		mining_power: 0,
		god_strength: 1,
		bonus_proportion: 1,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-5': {
		zhHans: '白山猿年',
		en: 'God Monkey',
		ja: '白山猿年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-6': {
		zhHans: '白山鳳凰',
		en: 'God Phoenix',
		ja: '白山鳳凰',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-7': {
		zhHans: '白山狐年',
		en: 'God Fox',
		ja: '白山狐年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-8': {
		zhHans: '白山猪年',
		en: 'God Boar',
		ja: '伊白山猪年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-9': {
		zhHans: '白山鼠年',
		en: 'God Rat',
		ja: '白山鼠年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-10': {
		zhHans: '白山牛年',
		en: 'God Ox',
		ja: '白山牛年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-11': {
		zhHans: '白山寅年',
		en: 'God Tiger',
		ja: '白山寅年',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-12': {
		zhHans: '稲荷神社',
		en: 'Inari Shrine',
		ja: '稲荷神社',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-13': {
		zhHans: '白山神社初詣',
		en: 'New Year',
		ja: '白山神社初詣',
		type: 9,
		mining_power: 0,
		god_strength: 3,
		bonus_proportion: 9,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-14': {
		zhHans: '役小角物語',
		en: 'Story of Ozunu',
		ja: '役小角物語',
		type: 9,
		mining_power: 0,
		god_strength: 2,
		bonus_proportion: 5,
		series: '',
		combined_list: [],
		combined: [],
	},
	'9-15': {
		zhHans: '白岩白山神社物語',
		en: 'Shiraiwa-Hakusan-Story',
		ja: '白岩白山神社物語',
		type: 9,
		mining_power: 0,
		god_strength: 4,
		bonus_proportion: 13,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-16': {
		zhHans: '巫女乃',
		en: 'Super Miko',
		ja: '巫女乃',
		type: 9,
		mining_power: 0,
		god_strength: 3,
		bonus_proportion: 9,
		series: '白山',
		combined_list: [],
		combined: [],
	},
	'9-17': {
		zhHans: '熱烈歓迎了法寺',
		en: 'Welcome to Ryohoji',
		ja: '熱烈歓迎了法寺',
		type: 9,
		mining_power: 0,
		god_strength: 5,
		bonus_proportion: 18,
		series: '了法寺',
		combined_list: [],
		combined: [],
	},
}
